/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';

function TaskDrawerFooterComponent({
  fieldsPermissions,
  task,
  isLoading,
  handleActionUpdate,
  setIsEditing,
  setIsConfirmationModalOpen,
  setConfirmationModalConfirmAction,
}) {
  const stagesNoConfirmation = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    14, // Aguardando Documentação
  ];

  const stagesNoDeny = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    13, // Reservado Sem Confirmação
    14, // Aguardando Documentação
  ];

  const stagesNoContactClient = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    13, // Reservado Sem Confirmação
    14, // Aguardando Documentação
  ];

  const shouldShowConfirmButton =
    !stagesNoConfirmation.includes(task.stage.id) &&
    fieldsPermissions?.LogisticConfirm?.access >= 1;

  const shouldShowNoContactButton =
    !stagesNoContactClient.includes(task.stage.id) &&
    fieldsPermissions?.LogisticNoClientContact?.access >= 1;

  const shouldShowDenyButton =
    !stagesNoDeny.includes(task.stage.id) && fieldsPermissions?.LogisticDeny?.access >= 1;

  const buttons = [
    <Button
      key="save"
      style={{
        backgroundColor: '#5cb85c', // Verde
        color: 'white',
        border: 'none',
        opacity: isLoading ? 0.3 : 1,
      }}
      loading={isLoading}
      onClick={() => handleActionUpdate('save')}
    >
      Salvar
    </Button>,
  ];

  if (shouldShowConfirmButton) {
    buttons.push(
      <Button
        key="confirm"
        style={{
          backgroundColor: '#5cb85c', // Verde
          color: 'white',
          border: 'none',
          opacity: isLoading ? 0.3 : 1,
        }}
        loading={isLoading}
        onClick={() => {
          setConfirmationModalConfirmAction('confirm');
          setIsConfirmationModalOpen(true);
        }}
      >
        Confirmar
      </Button>
    );
  }

  if (shouldShowDenyButton) {
    buttons.push(
      <Button
        key="deny"
        style={{
          backgroundColor: '#d9534f', // Vermelho
          color: 'white',
          border: 'none',
          opacity: isLoading ? 0.3 : 1,
        }}
        loading={isLoading}
        onClick={() => handleActionUpdate('deny')}
      >
        Negar
      </Button>
    );
  }

  if (shouldShowNoContactButton) {
    buttons.push(
      <Button
        key="no-contact"
        style={{
          backgroundColor: '#fff',
          color: '#333',
          border: 'none',
          opacity: isLoading ? 0.3 : 1,
          boxShadow: '0px 4px 4px #00000040',
        }}
        loading={isLoading}
        onClick={() => {
          setConfirmationModalConfirmAction('noClientContact');
          setIsConfirmationModalOpen(true);
        }}
      >
        Sem contato com cliente
      </Button>
    );
  }

  buttons.push(
    <Button
      key="cancel"
      style={{
        backgroundColor: '#fff',
        color: '#333',
        border: 'none',
        opacity: isLoading ? 0.3 : 1,
      }}
      loading={isLoading}
      onClick={() => setIsEditing(false)}
    >
      Cancelar
    </Button>
  );

  return buttons;
}

export default TaskDrawerFooterComponent;

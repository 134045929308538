/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Divider, Modal, Table } from 'antd';
import moment from 'moment';

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import './ModalStyle.scss';

function ConfirmationModal({ setIsVisible, onConfirm, actionType, taskList, selectedRowKeys }) {
  const [shouldDisableConfirmButton, setShouldDisableConfirmButton] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const selectedTasks = taskList.filter((task) => selectedRowKeys.includes(task.id));

  const fieldsToConfirm = [
    'mainInstructor', // Instrutor
    'instructorValue', // Valor Instrutor
    'startHour', // Horário de Início
    'responsiblePersonNameLogistic', // Nome Responsável
    'responsiblePersonPhoneLogistic', // Telefone Responsável
    'responsiblePersonEmailLogistic', // E-mail Responsável
    'nameResponsibleConfirmation', // Nome Responsável Confirmação
    'emailResponsibleConfirmation', // E-mail Responsável Confirmação
    'phoneResponsibleConfirmation', // Telefone Responsável Confirmação
    'transportCompany', // Empresa de Transporte
    'vehicleList', // Veículos
    'driverList', // Motoristas
    'transportValue', // Valor Transporte
    'boardingHour', // Horário de Embarque
    'postalCodeLogistic', // CEP
    'streetLogistic', // Rua
    'numberLogistic', // Número
    'districtLogistic', // Bairro
    'cityLogistic', // Cidade
    'stateLogistic', // Estado
  ];

  const formatTime = (time) => (time ? moment(time).format('HH:mm') : 'Não Preenchido');
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : 'Não Preenchido');
  const formatCurrency = (value) =>
    value
      ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(value)
      : 'Não Preenchido';

  const getFieldList = (formData) => ({
    comercial: [
      {
        key: 'dealClientCommercialName',
        label: 'Nome do Cliente',
        value: formData.dealClientCommercialName || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('dealClientCommercialName'),
      },
      {
        key: 'startDate',
        label: 'Data do Treinamento',
        value: formatDate(formData.startDate),
        mandatory: fieldsToConfirm.includes('startDate'),
      },
      {
        key: 'product',
        label: 'Produto + Complemento',
        value:
          formData.product && typeof formData.product === 'object'
            ? `${formData.product.name} ${formData.product.complement || ''}`.trim()
            : formData.product || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('product'),
      },
      {
        key: 'dealNotes',
        label: 'Observação Oficial do Negócio',
        value: formData.dealNotes || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('dealNotes'),
      },
      {
        key: 'commercialNotes',
        label: 'Observação Comercial',
        value: formData.commercialNotes || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('commercialNotes'),
      },
    ],
    logistica: [
      {
        key: 'mainInstructor',
        label: 'Instrutor',
        value: formData.mainInstructor?.userName || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('mainInstructor'),
      },
      {
        key: 'instructorValue',
        label: 'Valor Instrutor',
        value: formatCurrency(formData.instructorValue),
        mandatory: fieldsToConfirm.includes('instructorValue'),
      },
      {
        key: 'startHour',
        label: 'Horário de Início',
        value: formatTime(formData.startHour),
        mandatory: fieldsToConfirm.includes('startHour'),
      },
      {
        key: 'responsiblePersonNameLogistic',
        label: 'Nome Responsável',
        value: formData.responsiblePersonNameLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('responsiblePersonNameLogistic'),
      },
      {
        key: 'responsiblePersonPhoneLogistic',
        label: 'Telefone Responsável',
        value: formData.responsiblePersonPhoneLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('responsiblePersonPhoneLogistic'),
      },
      {
        key: 'responsiblePersonEmailLogistic',
        label: 'E-mail Responsável',
        value: formData.responsiblePersonEmailLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('responsiblePersonEmailLogistic'),
      },
      {
        key: 'nameResponsibleConfirmation',
        label: 'Nome Responsável Confirmação',
        value: formData.nameResponsibleConfirmation || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('nameResponsibleConfirmation'),
      },
      {
        key: 'emailResponsibleConfirmation',
        label: 'E-mail Responsável Confirmação',
        value: formData.emailResponsibleConfirmation || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('emailResponsibleConfirmation'),
      },
      {
        key: 'phoneResponsibleConfirmation',
        label: 'Telefone Responsável Confirmação',
        value: formData.phoneResponsibleConfirmation || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('phoneResponsibleConfirmation'),
      },
      {
        key: 'transportCompany',
        label: 'Empresa de Transporte',
        value: formData.transportCompany?.name || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('transportCompany'),
      },
      {
        key: 'vehicleList',
        label: 'Veículos',
        value: Array.isArray(formData.vehicleList)
          ? formData.vehicleList
              .map((item) => `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`)
              .join(', ')
          : 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('vehicleList'),
      },
      {
        key: 'driverList',
        label: 'Motoristas',
        value: Array.isArray(formData.driverList)
          ? formData.driverList.map((item) => item.userName).join(', ')
          : 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('driverList'),
      },
      {
        key: 'transportValue',
        label: 'Valor Transporte',
        value: formatCurrency(formData.transportValue),
        mandatory: fieldsToConfirm.includes('transportValue'),
      },
      {
        key: 'boardingHour',
        label: 'Horário de Embarque',
        value: formatTime(formData.boardingHour),
        mandatory: fieldsToConfirm.includes('boardingHour'),
      },
      {
        key: 'postalCodeLogistic',
        label: 'CEP',
        value: formData.postalCodeLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('postalCodeLogistic'),
      },
      {
        key: 'streetLogistic',
        label: 'Rua',
        value: formData.streetLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('streetLogistic'),
      },
      {
        key: 'numberLogistic',
        label: 'Número',
        value: formData.numberLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('numberLogistic'),
      },
      {
        key: 'districtLogistic',
        label: 'Bairro',
        value: formData.districtLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('districtLogistic'),
      },
      {
        key: 'cityLogistic',
        label: 'Cidade',
        value: formData.cityLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('cityLogistic'),
      },
      {
        key: 'stateLogistic',
        label: 'Estado',
        value: formData.stateLogistic || 'Não Preenchido',
        mandatory: fieldsToConfirm.includes('stateLogistic'),
      },
    ],
  });

  const isTaskValid = (task) =>
    fieldsToConfirm.every((field) => {
      const value = task[field];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== null && value !== '';
    });

  useEffect(() => {
    const allValid = selectedTasks.every(isTaskValid);
    setShouldDisableConfirmButton(!allValid);
  }, [selectedTasks]);

  function ExpandedRow(record) {
    const { comercial, logistica } = getFieldList(record);
    return (
      <div style={{ fontSize: '14px', lineHeight: '1.5' }}>
        <Divider orientation="left">Comercial</Divider>
        {comercial.map(({ label, value, mandatory }, index) => (
          <div key={`comercial-${index}`} style={{ marginBottom: '4px' }}>
            {mandatory ? <span style={{ color: '#d9534f' }}>* </span> : null}
            <strong>{label}:</strong>{' '}
            {value === 'Não Preenchido' ? (
              <span style={{ color: '#d9534f' }}>{value}</span>
            ) : (
              ` ${value}`
            )}
          </div>
        ))}

        <Divider orientation="left" style={{ marginTop: 40 }}>
          Logística
        </Divider>
        {logistica.map(({ label, value, mandatory }, index) => (
          <div key={`logistica-${index}`} style={{ marginBottom: '4px' }}>
            {mandatory ? <span style={{ color: '#d9534f' }}>* </span> : null}
            <strong>{label}:</strong>{' '}
            {value === 'Não Preenchido' ? (
              <span style={{ color: '#d9534f' }}>{value}</span>
            ) : (
              ` ${value}`
            )}
          </div>
        ))}
      </div>
    );
  }

  const toggleExpandAll = () => {
    if (expandedRowKeys.length === 0) {
      setExpandedRowKeys(selectedTasks.map((task) => task.id));
    } else {
      setExpandedRowKeys([]);
    }
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          {expandedRowKeys.length === 0 ? (
            <PlusCircleOutlined
              onClick={toggleExpandAll}
              style={{ cursor: 'pointer', fontSize: '16px' }}
            />
          ) : (
            <MinusCircleOutlined
              onClick={toggleExpandAll}
              style={{ cursor: 'pointer', fontSize: '16px' }}
            />
          )}
        </div>
      ),
      dataIndex: 'expand',
      key: 'expand',
      width: 50,
    },
    {
      title: 'Id Negócio',
      dataIndex: 'dealId',
      key: 'dealId',
      width: '110px',
    },
    {
      title: 'Id Turma',
      dataIndex: 'slotClassId',
      key: 'slotClassId',
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'dealClient',
      key: 'dealClient',
      width: '55%',
    },
  ];

  const buttonConfig = {
    confirm: {
      text: 'Confirmado',
      textColor: '#5cb85c',
      okButtonStyle: {
        backgroundColor: '#5cb85c',
        color: 'white',
        border: 'none',
        opacity: shouldDisableConfirmButton ? 0.3 : 1,
      },
    },
    deny: {
      text: 'Negado',
      textColor: '#d9534f',
      okButtonStyle: {
        backgroundColor: '#d9534f',
        color: 'white',
        border: 'none',
        opacity: shouldDisableConfirmButton ? 0.3 : 1,
      },
    },
    noClientContact: {
      text: 'Sem Contato com Cliente',
      textColor: '#333',
      okButtonStyle: {
        backgroundColor: '#fff',
        color: '#333',
        border: 'none',
        opacity: shouldDisableConfirmButton ? 0.3 : 1,
      },
    },
  };

  return (
    <Modal
      title="Confirmação de Ação"
      open={true}
      onOk={() => onConfirm(actionType)}
      onCancel={() => setIsVisible(false)}
      okText="Confirmar Ação"
      cancelText="Cancelar"
      width="fit-content"
      className="training-list-confirmation-modal"
      okButtonProps={{
        style: buttonConfig[actionType].okButtonStyle,
        disabled: shouldDisableConfirmButton,
      }}
    >
      <p>
        Você está prestes a alterar os registros selecionados para{' '}
        <strong style={{ color: buttonConfig[actionType].textColor }}>
          {buttonConfig[actionType].text}
        </strong>
        . Por favor, revise os detalhes abaixo e confirme se deseja prosseguir ou cancelar a
        operação.
      </p>
      <p style={{ fontStyle: 'italic' }}>
        Campos com &quot;<span style={{ color: '#d9534f' }}>*</span>&quot; são obrigatórios. Caso
        algum dos registros não possua todos os campos obrigatórios preenchidos, a ação não poderá
        ser confirmada.
      </p>

      <Table
        columns={columns}
        dataSource={selectedTasks}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => ExpandedRow(record),
          rowExpandable: () => true,
          expandedRowKeys,
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys(expandedKeys),
          expandIconColumnIndex: 0,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusCircleOutlined
                style={{ fontSize: '16px', cursor: 'pointer' }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <PlusCircleOutlined
                style={{ fontSize: '16px', cursor: 'pointer' }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </Modal>
  );
}

export default ConfirmationModal;

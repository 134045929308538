/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Collapse, Form, message, Modal, Upload } from 'antd';

import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

const { Panel } = Collapse;

export default function UploadFileModal({ isOpen, setIsOpen, screenType, taskIds }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState([]);

  const handleChange = (info) => {
    const newFileList = [...info.fileList.map((fileProps) => ({ ...fileProps, status: 'done' }))];
    setFileList(newFileList);
  };

  const handleImport = async (file) => {
    if (file.status !== 'removed') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  };

  const submit = async () => {
    if (fileList.length > 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append('excelFileBytes', fileList[0].originFileObj);

      let apiEndpoint;
      switch (screenType) {
        case 'instructor':
          apiEndpoint = '/Instructor/AddBatchFromExcel';
          break;
        case 'transport':
          apiEndpoint = '/Transport/AddBatchFromExcel';
          break;
        case 'driver':
          apiEndpoint = '/Driver/AddBatchFromExcel';
          break;
        case 'vehicle':
          apiEndpoint = '/Vehicle/AddBatchFromExcel';
          break;
        case 'product':
          apiEndpoint = '/Product/AddProductFromExcel';
          break;
        case 'taskList':
          apiEndpoint = '/Task/UpdateTaskSchedulingFromExcel';
          break;
        default:
          message.error('Oops! Tela inválida.');
          setLoading(false);
          return;
      }

      try {
        await api.post(apiEndpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        message.success('Arquivo importado com sucesso!');

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        message.error('Oops! Algo deu errado ao importar o arquivo.');
        Utils.logError(error);
        setErrors(error);
        setLoading(false);
      }
    }
  };

  const handleDownloadTemplate = async () => {
    let downloadEndpoint;
    let isPostRequest = false;
    setLoading(true);

    switch (screenType) {
      case 'instructor':
        downloadEndpoint = '/Instructor/DownloadInstructorsExcel';
        break;
      case 'transport':
        downloadEndpoint = '/Transport/DownloadTransportExcel';
        break;
      case 'driver':
        downloadEndpoint = '/Driver/DownloadDriverExcel';
        break;
      case 'vehicle':
        downloadEndpoint = '/Vehicle/DownloadVehiclesExcel';
        break;
      case 'taskList':
        downloadEndpoint = '/Task/ExportTaskDetails';
        isPostRequest = true;
        break;
      default:
        message.error('Oops! Tela inválida.');
        return;
    }

    try {
      let response;
      if (isPostRequest) {
        // Verifica se existem IDs para enviar
        if (!taskIds || taskIds.length === 0) {
          message.warn('Nenhuma tarefa selecionada para exportação.');
          return;
        }

        // Envia a lista de IDs diretamente
        response = await api.post(
          downloadEndpoint,
          taskIds, // Envia a lista de IDs diretamente
          {
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        response = await api.get(downloadEndpoint, {
          responseType: 'blob',
        });
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${screenType}_modelo_planilha.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      message.success('Download realizado com sucesso!');
    } catch (error) {
      message.error('Oops! Algo deu errado ao baixar o arquivo.');
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setFileList([]);
    setIsOpen(false);
    setErrors([]);
  };

  const getTitle = () => {
    switch (screenType) {
      case 'instructor':
        return 'Importação de Instrutores';
      case 'transport':
        return 'Importação de Transportes';
      case 'driver':
        return 'Importação de Motoristas';
      case 'vehicle':
        return 'Importação de Veículos';
      case 'product':
        return 'Importação de Produtos';
      case 'taskList':
        return 'Importação de Treinamento';
      default:
        return 'Importação';
    }
  };

  return (
    <Modal
      className="modal-Training-Complete"
      title={getTitle()}
      open={isOpen}
      cancelText="Fechar"
      onCancel={closeModal}
      destroyOnClose
      closable={!loading}
      maskClosable={!loading}
      footer={[
        <Button onClick={closeModal} disabled={loading}>
          Fechar
        </Button>,
        <Button
          form="uploadFileForm"
          type="primary"
          key="submit"
          htmlType="submit"
          disabled={loading}
          style={{
            backgroundColor: 'green',
            color: 'white',
            borderColor: 'green',
            opacity: loading ? 0.3 : 1,
          }}
        >
          Salvar
        </Button>,
        <Button
          type="ghost"
          key="download"
          icon={<DownloadOutlined />}
          onClick={() => {
            if (screenType !== 'product') {
              handleDownloadTemplate();
            } else {
              window.location.href =
                'https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/modelo-lista-produtos.xlsx?alt=media&token=79cda87c-0aaa-4faa-a8db-9b18731ed182';
            }
          }}
          disabled={loading}
        >
          Baixar Modelo
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="uploadFileForm"
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
        preserve={false}
      >
        <Form.Item
          name="excelFile"
          rules={[
            {
              required: true,
              message: 'Este campo é obrigatório!',
            },
          ]}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Upload
              beforeUpload={() => {
                setFileList([]);
              }}
              onChange={handleChange}
              customRequest={({ file }) => handleImport(file)}
              disabled={loading}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />} disabled={loading}>
                Selecione um arquivo
              </Button>
            </Upload>
          </div>
        </Form.Item>
      </Form>

      {errors.length > 0 && (
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition="end"
          style={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          {errors.map((error, index) => (
            <Panel header={`Linha ${error.rowNumber}`} key={index} style={{ textAlign: 'left' }}>
              <div style={{ textAlign: 'left' }}>
                {error.errors.map((err, idx) => (
                  <p
                    dangerouslySetInnerHTML={{ __html: `${err}` }}
                    key={idx}
                    style={{ margin: 0 }}
                  />
                ))}
              </div>
            </Panel>
          ))}
        </Collapse>
      )}
    </Modal>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import moment from 'moment';

import {
  FileExcelOutlined,
  FilterOutlined,
  LoadingOutlined,
  SearchOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import ModalUploadExcel from '../../Components/Instructor/ModalUploadExcel';
import TaskCommentFunctions from '../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../Services/axiosService';
import UserFunctions from '../User/Register/UserFunctions';

import {
  addTaskListTableFilter,
  downloadTaskListReport,
  fetchDrivers,
  fetchInstructors,
  fetchReasons,
  fetchSettings,
  fetchStages,
  fetchTaskColumnsSettings,
  fetchTaskListTableFilter,
  fetchTasks,
  fetchTrainingCenters,
  fetchTrainings,
  fetchTransportCompanies,
  fetchVehicles,
  updateTask,
  updateTaskListTableFilter,
} from './API/TaskListApi';
import ActionButtonsComponent from './Components/ActionButtonsComponent';
import AttachFilesModal from './Components/AttachFilesModal';
import ColumnsSettingsModal from './Components/ColumnsSettingsModal';
import ConfirmationModal from './Components/ConfirmationModal';
import DeletingModal from './Components/DeletionModal';
import EditableCellComponent from './Components/EditableCellComponent';
import TaskListColumns from './Shared/TaskListColumns';
import { mapEditedRows, mapSubmitData } from './Shared/TaskListFunctions';

import './TaskListStyle.scss';

const { RangePicker } = DatePicker;

export default function TaskListView() {
  const [formFilter] = Form.useForm();
  const [formTaskList] = Form.useForm();

  // Geral
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [loadingExporter, setLoadingExporter] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [taskList, setTaskList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filters, setfilters] = useState({});
  const [columnsSettings, setColumnsSettings] = useState([]);

  // Modal
  const [isModalDeletingVisible, setIsModalDeletingVisible] = useState(false);
  const [isModalConfirmationVisible, setIsModalConfirmationVisible] = useState(false);
  const [isColumnModalVisible, setIsColumnModalVisible] = useState(false);
  const [isModalAttachFilesVisible, setIsModalAttachFilesVisible] = useState(false);
  const [actionType, setActionType] = useState();

  // Options
  const [trainingCenterOptions, setTrainingCenterOptions] = useState([]);
  const [trainingOptions, setTrainingOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [bitrixUserOptions, setBitrixUserOptions] = useState([]);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [transportCompanyOptions, setTransportCompanyOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [isModalExportTask, setIsModalExportTask] = useState(false);

  // Edit
  const [editingIds, setEditingIds] = useState([]);

  // Filtros
  const [taskListTableFilter, setTaskListTableFilter] = useState();

  const todayDate = moment().add(-3, 'hour');
  const { canImportTasks, canHandleAnyStage } = JSON.parse(
    localStorage.getItem('conecta__userData')
  );

  const handleTaskListExport = async () => {
    if (editingIds.length > 0) {
      message.warn('Oops. Você não pode realizar esta ação durante a edição!');
      return;
    }

    setLoadingExporter(true);
    const tableColumns = TaskListColumns({
      editingIds,
      fieldsPermissions,
      columnsSettings,
      instructorOptions,
      driverOptions,
      vehicleOptions,
      reasonOptions,
      transportCompanyOptions,
      trainingCenterOptions,
    });
    const columnList = tableColumns.map(({ title, dataIndex }) => ({
      label: title,
      name: dataIndex,
    }));

    const taskListExportData = { Filters: filters, columnList };

    await downloadTaskListReport(taskListExportData);
    setLoadingExporter(false);
  };

  const handleSubmit = async () => {
    try {
      const editedRowsData = mapEditedRows(formTaskList, taskList, editingIds);
      const submitData = await mapSubmitData(
        editedRowsData,
        trainingCenterOptions,
        instructorOptions,
        driverOptions,
        transportCompanyOptions,
        vehicleOptions,
        reasonOptions,
        stageOptions
      );

      setIsLoading(true);
      for (let index = 0; index < submitData.length; index += 1) {
        const newTask = submitData[index];
        const oldTask = JSON.parse(JSON.stringify(taskList.find(({ id }) => id === newTask.id)));

        await updateTask(newTask, oldTask, driverOptions, vehicleOptions);
      }

      message.success('Atualização dos Treinamentos concluída!');
      window.location.reload(); // Recarrega a página
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar atualizar os Treinamentos selecionados!');
      setIsLoading(false);
    }
  };

  const handleFetchTasks = async (pFilters = filters) => {
    if (editingIds.length > 0) {
      message.warn('Oops. Você não pode realizar esta ação durante a edição!');
      return;
    }

    setSelectedRowKeys([]);
    setEditingIds([]);
    setfilters(pFilters);
    const taskFilters = pFilters;

    if (pFilters?.period) {
      const [start, end] = pFilters.period;
      taskFilters.startDate = start;
      taskFilters.endDate = end;
    }

    const tasks = await fetchTasks(taskFilters);

    setTaskList(tasks);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [
        instructors,
        drivers,
        transportCompanies,
        vehicles,
        settings,
        trainingCenters,
        reasons,
        trainings,
        stages,
        colSettings,
        resTaskListTableFilter,
      ] = await Promise.all([
        fetchInstructors(),
        fetchDrivers(),
        fetchTransportCompanies(),
        fetchVehicles(),
        fetchSettings(),
        fetchTrainingCenters(),
        fetchReasons(),
        fetchTrainings(),
        fetchStages(),
        fetchTaskColumnsSettings(),
        fetchTaskListTableFilter(),
      ]);

      setInstructorOptions(instructors);
      setDriverOptions(drivers);
      setTransportCompanyOptions(transportCompanies);
      setVehicleOptions(vehicles);
      setTrainingCenterOptions(trainingCenters);
      setReasonOptions(reasons);
      setTrainingOptions(trainings);
      setStageOptions(stages);
      setColumnsSettings(colSettings);
      setTaskListTableFilter(resTaskListTableFilter);

      await UserFunctions.fetchBitrixUsers(settings.bitrixWebhookUrl, setBitrixUserOptions);

      await handleFetchTasks(resTaskListTableFilter?.filters || { period: [todayDate, todayDate] });
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as opções de filtro!');
    } finally {
      setIsLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: (editingIds.length > 0 || record.selectable === false) && !canHandleAnyStage,
      style: record.hideCheckbox ? { display: 'none' } : {},
    }),
  };

  const handleActionUpdate = async (type) => {
    try {
      setIsLoading(true);
      const userData = JSON.parse(localStorage.getItem('conecta__userData'));
      let newStageId = null;
      let otherDataChange = null;

      switch (type) {
        case 'confirm':
          newStageId = 3; // 3 = Estágio "Confirmado"
          otherDataChange = {
            confirmedBy: userData.name,
            idConfirmedBy: parseInt(userData.id, 10),
            confirmationDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          };
          break;
        case 'deny':
          newStageId = 4; // 4 = Estágio "Negado"
          break;
        case 'noClientContact':
          newStageId = 13; // 13 = Estágio "Reservado Sem Confirmação"
          break;
        default:
          break;
      }

      const newStage = stageOptions.find(({ id }) => id === newStageId);

      for (let index = 0; index < selectedRowKeys.length; index += 1) {
        let data = taskList.find(({ id }) => id === selectedRowKeys[index]);
        const oldTaskData = JSON.parse(JSON.stringify(data));

        // Atualiza o objeto antes de enviar
        data = { ...data, ...otherDataChange, stage: newStage };

        // Enviar para atualizar
        await api.put('/Task/UpdateTaskScheduling', data);

        // Eventos secundárias
        await TaskCommentFunctions.addTaskCommentActivities(data, oldTaskData, 'SchedulingList');
        await TaskCommentFunctions.addTaskCommentHistory(data, oldTaskData, 'SchedulingList');
      }

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 3000); // 3 Segundos
      });

      message.success('Atualização dos Treinamentos concluída!');
      window.location.reload(); // Recarrega a página
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar atualizar os Treinamentos selecionados!');
      setIsLoading(false);
    }
  };

  const handleActionClick = (type) => {
    if (['confirm', 'deny', 'noClientContact'].includes(type)) {
      setActionType(type);
      setIsModalConfirmationVisible(true);
    } else {
      Utils.error('Tipo de ação inválido:', type);
    }
  };

  const handleFilterSave = async () => {
    setIsFilterLoading(true);
    const filterFormValues = formFilter.getFieldsValue();
    const submitData = {};
    submitData.filters = filterFormValues;

    if (taskListTableFilter) {
      submitData.id = taskListTableFilter.id;

      await updateTaskListTableFilter(submitData);
    } else {
      await addTaskListTableFilter(submitData);
      setTaskListTableFilter(await fetchTaskListTableFilter());
    }
    setIsFilterLoading(false);
  };

  const handleFilterSubmit = async (pFilters) => {
    setIsLoading(true);
    await handleFetchTasks(pFilters);
    setIsLoading(false);
  };

  const renderFilter = () => (
    <Form
      form={formFilter}
      disabled={isLoading || isFilterLoading}
      name="TaskListFilter"
      onFinish={handleFilterSubmit}
      layout="vertical"
      autoComplete="off"
      initialValues={
        taskListTableFilter?.filters || {
          period: [todayDate, todayDate],
          responsibles: [],
          stages: [],
          trainingCenterIds: [],
          trainings: [],
          typeTrainings: [],
        }
      }
      style={{ width: '25vw', backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}
    >
      <Form.Item name="period" label="Período">
        <RangePicker format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item label="Centro de Treinamento" name="trainingCenterIds">
        <Select
          options={trainingCenterOptions}
          placeholder="Selecione um CT"
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          showSearch
          allowClear
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(
              values,
              trainingCenterOptions,
              'trainingCenterIds',
              formFilter
            )
          }
        />
      </Form.Item>
      <Form.Item label="Responsável" name="responsibles">
        <Select
          options={bitrixUserOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, bitrixUserOptions, 'responsibles', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Estágio" name="stages">
        <Select
          options={stageOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16, zIndex: 1050 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, stageOptions, 'stages', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Treinamento" name="trainings">
        <Select
          options={trainingOptions}
          allowClear
          placeholder="Selecione"
          optionFilterProp="label"
          showSearch
          dropdownStyle={{ borderRadius: 16 }}
          removeIcon={false}
          mode="multiple"
          maxTagCount={1}
          onChange={(values) =>
            Utils.handleSelectAllChange(values, trainingOptions, 'trainings', formFilter)
          }
        />
      </Form.Item>
      <Form.Item label="Tipo" name="typeTrainings">
        <Select
          options={[
            { label: 'CT', value: 'CT' },
            { label: 'In Company', value: 'In Company' },
            { label: 'EAD', value: 'EAD' },
          ]}
          optionFilterProp="label"
          dropdownStyle={{ borderRadius: 16 }}
          mode="multiple"
          maxTagCount={3}
        />
      </Form.Item>
      <Row gutter={[12]}>
        <Col span={8}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            style={{ width: '100%' }}
          >
            Buscar
          </Button>
        </Col>
        <Col span={8}>
          <Button type="default" onClick={handleFilterSave} style={{ width: '100%' }}>
            Salvar
          </Button>
        </Col>
        <Col span={8}>
          <Button
            type="default"
            onClick={() =>
              formFilter.setFieldsValue({
                period: [],
                responsibles: [],
                stages: [],
                trainingCenterIds: [],
                trainings: [],
                typeTrainings: [],
              })
            }
            style={{ width: '100%' }}
          >
            Limpar
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const tableComponents = {
    body: {
      cell: EditableCellComponent,
    },
  };

  useEffect(() => {
    fetchData();

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'TrainingList') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
  }, []);

  if (isLoading || !fieldsPermissions) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!isLoading && fieldsPermissions) {
    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Tooltip title="Personalizar exibição de colunas">
              <Button
                onClick={() => {
                  if (editingIds.length > 0) {
                    message.warn('Oops. Você não pode realizar esta ação durante a edição!');
                    return;
                  }

                  setIsColumnModalVisible(true);
                }}
                icon={<SettingOutlined />}
                disabled={editingIds.length > 0}
              />
            </Tooltip>

            {fieldsPermissions?.ExportBtn?.access >= 1 && (
              <Tooltip title="Exportar Lista de Treinamentos">
                <Button
                  onClick={handleTaskListExport}
                  icon={loadingExporter ? <LoadingOutlined /> : <FileExcelOutlined />}
                  disabled={loadingExporter || editingIds.length > 0}
                />
              </Tooltip>
            )}

            {canImportTasks && (
              <Tooltip title="Importar Treinamentos p/ Planilha">
                <Button
                  onClick={() => {
                    if (selectedRowKeys.length > 0) {
                      setIsModalExportTask(true);
                    }
                  }}
                  icon={<UploadOutlined />}
                  disabled={
                    loadingExporter || editingIds.length > 0 || selectedRowKeys.length === 0
                  }
                />
              </Tooltip>
            )}

            <Tooltip title="Filtros">
              {editingIds.length === 0 ? (
                <Popover content={renderFilter} trigger="click" overlayClassName="custom-popover">
                  <Button type="default" className="filterComponent" icon={<FilterOutlined />} />
                </Popover>
              ) : (
                <Button
                  type="default"
                  className="filterComponent"
                  icon={<FilterOutlined />}
                  disabled={editingIds.length > 0}
                />
              )}
            </Tooltip>
          </div>

          <ActionButtonsComponent
            selectedRowKeys={selectedRowKeys}
            taskList={taskList}
            editingIds={editingIds}
            fieldsPermissions={fieldsPermissions}
            handleActionClick={handleActionClick}
            setIsModalDeletingVisible={setIsModalDeletingVisible}
            setIsModalAttachFilesVisible={setIsModalAttachFilesVisible}
            setEditingIds={setEditingIds}
            handleSubmit={handleSubmit}
          />
        </div>

        <Form
          form={formTaskList}
          disabled={isLoading}
          name="TaskListForm"
          layout="vertical"
          autoComplete="off"
          component={false}
        >
          <Form.List name="list">
            {() => (
              <Table
                columns={TaskListColumns({
                  editingIds,
                  fieldsPermissions,
                  columnsSettings,
                  instructorOptions,
                  driverOptions,
                  vehicleOptions,
                  reasonOptions,
                  transportCompanyOptions,
                  trainingCenterOptions,
                  formTaskList,
                })}
                components={tableComponents}
                rowSelection={rowSelection}
                dataSource={taskList}
                scroll={{ y: '75vh' }}
                size="small"
                pagination={{
                  position: ['bottomRight'],
                  size: 'default',
                  defaultPageSize: 10,
                  pageSizeOptions: [10, 20, 30, 50, 100, 200, 500, 1000, 5000, 10000],
                  showSizeChanger: true,
                  total: taskList?.length ?? 0,
                  showTotal: (total) => `${total} treinamentos`,
                }}
                className="task-list-table"
              />
            )}
          </Form.List>
        </Form>

        {isModalDeletingVisible && (
          <DeletingModal
            taskList={taskList}
            setLoading={setIsLoading}
            setIsVisible={setIsModalDeletingVisible}
            reasonsOptions={reasonOptions}
            selectedRowKeys={selectedRowKeys}
          />
        )}

        {isModalConfirmationVisible && (
          <ConfirmationModal
            setIsVisible={setIsModalConfirmationVisible}
            onConfirm={handleActionUpdate}
            actionType={actionType}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
          />
        )}

        {isColumnModalVisible && (
          <ColumnsSettingsModal
            columns={TaskListColumns}
            setIsVisible={setIsColumnModalVisible}
            columnsSettings={columnsSettings}
            fieldsPermissions={fieldsPermissions}
          />
        )}

        {isModalAttachFilesVisible && (
          <AttachFilesModal
            setIsVisible={setIsModalAttachFilesVisible}
            taskList={taskList}
            selectedRowKeys={selectedRowKeys}
            driverOptions={driverOptions}
            vehicleOptions={vehicleOptions}
            stageOptions={stageOptions}
          />
        )}

        {isModalExportTask && (
          <ModalUploadExcel
            setIsOpen={setIsModalExportTask}
            isOpen={isModalExportTask}
            screenType="taskList"
            taskIds={selectedRowKeys}
          />
        )}
      </>
    );
  }
}
